<template>
  <div>
    <ek-dialog
      ref="addDialog"
      title="إضافة مستخدم"
      size="md"
      placeholder="ابحث عن مستخدمين"
      btnText="اضافة حساب"
      @ok="submitForm"
      @close="resetForm"
      @search="setSearch"
    >
      <template #body>
        <account-form class="w-100" ref="accountForm" @closeForm="closeForm"> </account-form>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import AccountForm from "./AccountForm.vue";
export default {
  components: {
    AccountForm,
  },
  data: () => ({}),
  methods: {
    submitForm() {
      this.$refs.accountForm.submit();
    },
    resetForm() {
      this.$refs.accountForm.reset();
    },
    closeForm() {
        this.$refs.addDialog.close();
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name", "username", "email", "phoneNumber"],
        query,
      });
    },
  },
};
</script>
