<template>
  <div class="account-form">
    <ValidationObserver ref="form">
      <ek-input-text
        :rules="[{ type: 'required', message: 'الاسم مطلوب' }]"
        name="name"
        v-model="user.name"
        placeholder="أدخل الاسم الكامل"
        label="الاسم الكامل"
      >
      </ek-input-text>

      <ek-input-text
        :rules="[{ type: 'required', message: 'اسم المستخدم مطلوب' }]"
        name="username"
        v-model="user.userName"
        placeholder="أدخل اسم المستخدم"
        label="اسم المستخدم"
      >
      </ek-input-text>

      <ek-input-text
        :rules="[
          { type: 'required', message: 'الاسم مطلوب' },
          { type: 'email', message: 'يرجى ادخال بريد الكتروني صحيح' },
        ]"
        name="email"
        v-model="user.email"
        placeholder="ادخل البريد الالكتروني"
        label="البريد الالكتروني"
      >
      </ek-input-text>

      <ek-input-text
        :rules="[
          { type: 'required', message: 'كلمة المرور مطلوبة' },
          { type: 'min:4', message: 'كلمة المرور يجب ان تحوي 4 محارف على الاقل' },
        ]"
        v-model="user.password"
        name="password"
        placeholder="أدخل  كلمة المرور"
        label="كلمة المرور"
      >
      </ek-input-text>

      <ek-input-text
        :rules="[
          { type: 'required', message: 'يرجى اعادة ادخال كلمة المرور' },
          { type: 'confirmed:password', message: 'كلمة المرور غير متطابقة' },
        ]"
        v-model="passwordVer"
        name="تأكيد كلمة المرور"
        placeholder=" أدخل  كلمة المرور مجددا"
        label="تأكيد كلمة المرور "
      >
      </ek-input-text>

      <ek-input-text
        :rules="[
          { type: 'required', message: 'رقم الهاتف مطلوب' },
          { type: 'mobile', message: 'رقم الهاتف غير صالح' },
        ]"
        name="phoneNumber"
        v-model="user.phoneNumber"
        placeholder="أدخل الهاتف"
        label="رقم الهاتف"
      >
      </ek-input-text>

      <ek-date-picker
        :rules="[{ type: 'required', message: 'تاريخ الميلاد مطلوب' }]"
        v-model="user.birthDate"
        name="تاريخ الميلاد"
        placeholder=" أدخل تاريخ الميلاد"
        label="تاريخ الميلاد "
      >
      </ek-date-picker>

      <ek-input-select
        @change="handleChange"
        valueLabel="value"
        v-model="user.role"
        :rules="[{ type: 'required', message: 'صلاحية المستخدم مطلوبة' }]"
        name="الصلاحيات"
        :options="[
          { name: 'مسؤول', value: 0 },
          { name: 'مستخدم', value: 1 },
        ]"
        placeholder=" اختر صلاحية المستخدم"
        label="الصلاحيات"
      >
      </ek-input-select>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    passwordVer: "",
    user: {
      userName: "",
      password: "",
      name: "",
      phoneNumber: "",
      email: "",
      birthDate: "",
      role: "",
    },
  }),
  computed: {},
  methods: {
    ...mapActions(["addDashUser"]),
    submit() {
      this.$refs["form"].validate().then((valid) => {
        if (valid) {
          this.addDashUser(this.user);
          this.$emit("closeForm")
        }
      });
    },
    reset() {
      this.$refs["form"].reset();
      this.passwordVer = "";
      Object.assign(this.user, {
        userName: "",
        password: "",
        name: "",
        phoneNumber: "",
        email: "",
        birthDate: "",
        role: "",
      });
    },
    handleChange(e) {
      console.log("changed", e);
    },
  },
};
</script>
